import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import { getEstimateTax } from '@gf/cross-platform-lib/modules/AcquisitionV2';
import { SchoolCart, TicketCart } from '@gf/cross-platform-lib/models';

import { ProductType, TaxItemRequest, TaxProductType, TaxSchoolType } from '@gf/cross-platform-lib/interfaces';
import { GOFAN_SCHOOL_TYPES } from '@gf/cross-platform-lib/constants';
import { TaxFlagRule, TaxFlagRules } from '@gf/cross-platform-lib/providers/LaunchDarkly';

interface Ticket extends TicketCart {
  financialSchoolID?: string;
  taxInfo: {
    product_type: TaxProductType;
    org_type: TaxSchoolType;
    postal_code: string;
    org_ownership?: string;
  };
  eventCount?: number;
}

export const getProductType = (isAthletic: boolean, productType: string = '') => {
  if (
    productType.toUpperCase() === ProductType.DONATION ||
    productType.toUpperCase() === ProductType.DONATION_ONGOING
  ) {
    return TaxProductType.DONATION;
  } else {
    return isAthletic ? TaxProductType.ATHLETIC : TaxProductType.NON_ATHLETIC;
  }
};

export const getDividedPriceList = (total: number, count: number) => {
  if (count < 1) {
    return [];
  }
  if (total <= 0) {
    return Array.from({ length: count }).map(_ => 0);
  }
  const price = Math.floor(parseFloat((total / count).toFixed(4)) * 100) / 100;

  const priceList = Array.from({ length: count - 1 }).map(_ => price);
  priceList.push(Math.round((total - price * (count - 1)) * 100) / 100);

  return priceList;
};

export const getSchoolType = (gofanSchoolType?: string) => {
  if (!gofanSchoolType) return TaxSchoolType.K12;

  const gofanSchoolTypeUpper = gofanSchoolType.toUpperCase();
  if (gofanSchoolTypeUpper === GOFAN_SCHOOL_TYPES.SINGLE.COLLEGE.toUpperCase()) {
    return TaxSchoolType.HIGHER_EDUCATION;
  } else if (gofanSchoolTypeUpper === GOFAN_SCHOOL_TYPES.OTHER.FOR_PROFIT.toUpperCase()) {
    return TaxSchoolType.FOR_PROFIT;
  } else if (gofanSchoolTypeUpper === GOFAN_SCHOOL_TYPES.OTHER.NON_PROFIT.toUpperCase()) {
    return TaxSchoolType.NON_PROFIT;
  } else {
    return TaxSchoolType.K12;
  }
};

export const getRoundingNumber = (price: number) => (price < 0 ? 0 : Math.round(price * 100) / 100);

export const getAllTickets = (cartSchools: SchoolCart[]): Ticket[] => {
  const tickets = [];
  for (const school of cartSchools) {
    for (const event of school.events) {
      for (const ticket of event.tickets) {
        tickets.push({
          ...ticket,
          taxInfo: {
            product_type: getProductType(
              !!event.activity?.isAthletic,
              event.ticketTypes.find(tkType => `${tkType.id}` === `${ticket.id}`)?.productType
            ),
            org_type: getSchoolType(school.gofanSchoolType),
            postal_code: event.zipCode,
            org_ownership: event.financialSchoolPublicPrivate?.toLowerCase()
          },
          financialSchoolID: event.financialSchoolID,
          ...(event.isSeason ? { eventCount: event.eventIds.length } : {})
        });
      }
    }
  }
  return tickets;
};

export const isEnabledTaxRule = (rule: TaxFlagRule) => {
  let isEnabled = false;
  if (rule?.all) {
    isEnabled = true;
  } else if (!isEmpty(rule?.in) || !isEmpty(rule?.not_in)) {
    isEnabled = true;
  }
  return isEnabled;
};

export const isTaxEnable = (taxFlagRules: TaxFlagRules) =>
  !isEmpty(taxFlagRules) &&
  (isEnabledTaxRule(taxFlagRules.financial_schools) || isEnabledTaxRule(taxFlagRules.product_types));

const isEnabledRule = (rule: TaxFlagRule, value: string) => {
  let isEnabled = false;
  if (rule?.all) {
    isEnabled = true;
  } else if (!isEmpty(rule?.in) || !isEmpty(rule?.not_in)) {
    isEnabled =
      (isEmpty(rule?.in) || rule.in?.includes(value)) && (isEmpty(rule?.not_in) || !rule.not_in?.includes(value));
  }
  return isEnabled;
};

export const isApplyTax = (product_type: TaxProductType, financial_school: string, taxFlagRules: TaxFlagRules) => {
  if (isEmpty(taxFlagRules) || isEmpty(product_type) || isEmpty(financial_school)) {
    return false;
  }
  const { product_types, financial_schools } = taxFlagRules;

  const isProductTypeEnabled = isEnabledRule(product_types, product_type);
  const isOrgOwnershipEnabled = isEnabledRule(financial_schools, financial_school);

  return isProductTypeEnabled && isOrgOwnershipEnabled;
};

const getTaxItemsInfo = (ticket: Ticket, index: number) => {
  const hiddenFeeBase = ticket.hiddenFeeBase || 0;
  const discountQuantity = ticket.promotion?.discountQuantity || 0;
  const discountPerTicket = ticket.promotion?.discountPerTicket || 0;
  const numberOfTickets = ticket.packCount > 1 ? ticket.packCount : 1;

  const discountValue =
    discountQuantity > 0 && (index + 1) * numberOfTickets <= discountQuantity ? discountPerTicket * numberOfTickets : 0;
  const discountedPrice = ticket.price - discountValue;
  const totalPrice = getRoundingNumber(discountedPrice - hiddenFeeBase);

  if (discountedPrice > 0 && (hiddenFeeBase > 0 || totalPrice > 0)) {
    if ((ticket.eventCount ?? 0) > 1 || ticket.packCount > 1) {
      const dividedPriceList = getDividedPriceList(totalPrice, ticket.eventCount || ticket.packCount);
      const dividedFeeList = getDividedPriceList(
        hiddenFeeBase > 0 ? hiddenFeeBase : ticket.fee,
        ticket.eventCount || ticket.packCount
      );

      const items = dividedPriceList.map((price, i) => ({
        ...ticket.taxInfo,
        base_price: price,
        surcharge_fee: getRoundingNumber(dividedFeeList[i])
      }));
      return items;
    }

    return [
      {
        ...ticket.taxInfo,
        base_price: totalPrice,
        surcharge_fee: getRoundingNumber(hiddenFeeBase > 0 ? hiddenFeeBase : ticket.fee)
      }
    ];
  }
  return [];
};

const taxRequiredFields = ['product_type', 'org_type', 'postal_code', 'org_ownership'];
export const getTaxRequestData = (cartSchools: SchoolCart[], taxFlagRules: TaxFlagRules) => {
  let items: TaxItemRequest[] = [];
  const tickets = getAllTickets(cartSchools);
  for (const ticket of tickets) {
    const taxInfo = ticket.taxInfo;
    const isValidTaxInfo = taxRequiredFields.every(
      key => !isEmpty((taxInfo as { [key: string]: string | undefined })[key])
    );

    if (isApplyTax(taxInfo.product_type, ticket.financialSchoolID || '', taxFlagRules) && isValidTaxInfo) {
      for (let i = 0; i < ticket.quantity; i++) {
        const taxItems = getTaxItemsInfo(ticket, i).filter(
          item => isNumber(item?.base_price) && isNumber(item?.surcharge_fee)
        );

        items.push(...(taxItems as TaxItemRequest[]));
      }
    }
  }
  return { taxRequestData: { items } };
};

export const getTax = async (cartSchools: SchoolCart[], taxFlagRules: TaxFlagRules) => {
  if (!isTaxEnable(taxFlagRules)) {
    return 0;
  }
  const { taxRequestData } = getTaxRequestData(cartSchools, taxFlagRules);
  const tax = await getEstimateTax(taxRequestData);
  return tax;
};
