// @ts-nocheck
import * as React from 'react';
import { Platform } from 'react-native';
import Svg, { Path, SvgProps } from 'react-native-svg';

export const SubtractIcon = (props: SvgProps) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox={Platform.OS === 'android' || Platform.OS === 'ios' ? '0 0 32 32' : '0 -5 32 32'}
    style={{
      enableBackground: 'new 0 0 32 32'
    }}
    fill='currentColor'
    xmlSpace='preserve'
    {...props}
  >
    <Path d='M8 15h16v2H8z' />
    <Path
      style={{
        fill: 'none'
      }}
      d='M0 0h32v32H0z'
    />
  </Svg>
);
