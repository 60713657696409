import { baseStyles } from './NumberInput.styles.base';
import { css } from 'styled-components';

export const styles = {
  ...baseStyles,
  inputNumber: css<{ width: number; height: number }>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    border: 2px solid #dbdbdb;
    border-radius: 6px;
    text-align: center;
  `,
  button: css<{ height: number }>`
    width: ${({ height }) => `${height}px`};
    height: ${({ height }) => `${height}px`};
    border: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border-radius: 4px;
    position: absolute;
    &:hover {
      background-color: #e8e8e8;

      & ~ div {
        background-color: transparent;
      }
    }

    &:active {
      background-color: #dbdbdb;
    }

    &:disabled {
      cursor: not-allowed;
    }
  `
};
