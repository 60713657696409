import React from 'react';
import { View } from 'react-native';
import { styles } from './SeatSelectionHoldExpired.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { Carbonicons, StyledText } from '@gf/cross-platform-lib/components';

const DescriptionWrapper = styled(View)`
  ${styles.descriptionWrapper}
`;

const RemovedTextWrapper = styled(View)`
  ${styles.removedTextWrapper}
`;

const RemovedText = styled(View)`
  ${styles.removedText}
`;
interface SeatSelectionHoldExpiredProps {
  isMobile: boolean;
}

export const SeatSelectionHoldExpired = ({ isMobile }: SeatSelectionHoldExpiredProps) => {
  return (
    <DescriptionWrapper>
      <RemovedTextWrapper>
        <View style={{ paddingRight: 17 }}>
          <Carbonicons name='warning-filled' size={20} color={'#f1c21c'} figurecolor='black' />
        </View>
        <RemovedText isMobile={isMobile}>
          <StyledText typeToken='bodyCompact01SemiBold'>Some items have been removed</StyledText>
          <StyledText typeToken='body01'>Items that have been held for 15 minutes have been removed.</StyledText>
        </RemovedText>
      </RemovedTextWrapper>
    </DescriptionWrapper>
  );
};

export default SeatSelectionHoldExpired;
