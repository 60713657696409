export enum TaxProductType {
  DONATION = 'donation',
  ATHLETIC = 'athletic_event_ticket',
  NON_ATHLETIC = 'non_athletic_event_ticket'
}

export enum TaxSchoolType {
  HIGHER_EDUCATION = 'higher_education',
  FOR_PROFIT = 'for_profit',
  NON_PROFIT = 'non_profit',
  K12 = 'k12'
}

export interface TaxItemRequest {
  base_price: number;
  surcharge_fee: number;
  product_type: TaxProductType;
  postal_code: string;
  org_type: TaxSchoolType;
  org_ownership: string | undefined;
}
export interface TaxItem {
  base_price: number;
  surcharge_fee: number;
  tax_fee: number;
  tax_price: number;
  total: number;
}

export interface TaxRequest {
  items: TaxItemRequest[];
}

export interface TaxResponse {
  items: TaxItem[];
  total: number;
}
