import capitalize from 'lodash/capitalize';
import dayjs from 'dayjs';
import { Cart, TicketCart, TicketCartPromotion, UpdatedPromotionInfo } from '../Cart';
import {
  DISCOUNT_TYPES,
  DiscountPromoInfo,
  Promotion,
  PromotionLockResponse,
  PromotionLockResponseError
} from '@gf/cross-platform-lib/interfaces';
import pluralize from 'pluralize';
import {
  PROMOTION_ERRORS,
  PROMOTION_ERROR_MESSAGES,
  MAX_TICKETS_PER_PROMO_PER_ORDER,
  DEFAULT_HOLDING_PROMO_CODE_TIME,
  MINUTES
} from '@gf/cross-platform-lib/constants';
import min from 'lodash/min';

const isNotExistedCode = (errorMessage: string) => errorMessage.includes(PROMOTION_ERRORS.CODE_IS_NOT_EXISTED);
const isCodeNotAvailable = (errorMessage: string) => errorMessage.includes(PROMOTION_ERRORS.CODE_IS_NOT_AVAILABLE);
const isInvalidPromoCode = (response: PromotionLockResponse) => {
  const promotion = (response as Promotion)?._embedded?.promotion;
  if (!promotion || promotion.required) {
    return true;
  }
  return false;
};
const getDateFromErrorMessage = (message: string) => {
  const splitDate = message.split('.')[0].split(' ');
  return `${capitalize(splitDate[6])} ${splitDate[7]} ${splitDate[8]}`;
};

const codeIsNotAvailableMessage = (errorMessage: string) => {
  const date = getDateFromErrorMessage(errorMessage);
  return `${PROMOTION_ERROR_MESSAGES.CODE_IS_NOT_AVAILABLE.replace('${date}', date)}`;
};

const multiCodeIsNotAvailableMessage = (errorMessages: string[]) => {
  const dates = errorMessages.map(message => getDateFromErrorMessage(message));
  const closestDate = dates.sort((a, b) => (dayjs(a).isBefore(dayjs(b)) ? -1 : 1))[0];

  return `${PROMOTION_ERROR_MESSAGES.CODE_IS_NOT_AVAILABLE.replace('${date}', closestDate)}`;
};

export const createTask = (timeout: number, taskId: string, doTask: Function): Task | null => {
  if (timeout <= 0) {
    doTask();
    return null;
  } else {
    const timer = setTimeout(() => {
      doTask();
    }, timeout);
    return {
      timer,
      taskId
    };
  }
};

export interface Task {
  timer: NodeJS.Timeout;
  taskId: string;
}

export class PromotionService {
  private static instance: PromotionService;
  tasks: Map<string, Task>;
  showedPromotionModals: Set<string>;
  cartService: Cart;
  holdingTime: number;
  listRemainingAt: number[];

  public static getInstance(holdingInMinutes?: number) {
    if (!PromotionService.instance) {
      PromotionService.instance = new PromotionService(holdingInMinutes);
    }
    return PromotionService.instance;
  }

  private constructor(holdingInMinutes?: number) {
    this.tasks = new Map<string, Task>();
    this.showedPromotionModals = new Set<string>();
    this.cartService = Cart.getInstance();
    this.holdingTime = holdingInMinutes ? MINUTES(holdingInMinutes) : DEFAULT_HOLDING_PROMO_CODE_TIME;
    this.listRemainingAt = [];
  }

  //get remaining time to reset promotion code
  getPromoCodeRemainingTime() {
    const newListRemainingAt = this.listRemainingAt.filter(time => time + this.holdingTime - Date.now() > 0);
    this.listRemainingAt = newListRemainingAt;

    const remainingAt = min(newListRemainingAt) || 0;
    const expireTime = remainingAt + this.holdingTime - Date.now();

    if (expireTime < 0) {
      return 0;
    } else if (expireTime > DEFAULT_HOLDING_PROMO_CODE_TIME) {
      return DEFAULT_HOLDING_PROMO_CODE_TIME;
    }
    return expireTime;
  }

  public addRemovePromotionTask(ticket: TicketCart) {
    const { promotion, id: ticketId } = ticket;

    if (promotion) {
      const remainingTime = promotion.updatedAt + this.holdingTime - Date.now();
      if (remainingTime > 0) {
        this.listRemainingAt.push(promotion.updatedAt);
      }
      const task = createTask(remainingTime, ticketId, () => {
        this.cartService.removeTicketsPromotion(ticketId);
      });
      if (task) {
        const { taskId } = task;
        if (this.tasks.get(taskId)?.timer) {
          clearTimeout(this.tasks.get(taskId)!.timer);
        }
        this.tasks.set(taskId, task);
      }
    }
  }

  public addTasksToRemoveCode() {
    const cartService = Cart.getInstance();
    const promotionService = this;
    const tickets = Array.from(cartService.getTicketCarts()).filter(ticket => !!ticket.promotion);
    for (const ticket of tickets) {
      promotionService.addRemovePromotionTask(ticket);
    }
  }

  public clearTasks(renewTask?: boolean) {
    if (this.tasks) {
      this.tasks.forEach(task => {
        if (task.timer) {
          clearTimeout(task.timer);
        }
      });
      if (renewTask === true) {
        this.tasks = new Map<string, Task>();
      }
    }
  }

  public onPromoCodeApplied(
    data: UpdatedPromotionInfo,
    showPromotionLimitModal: (message: { title: string; description: string }) => void
  ) {
    const { associatedTickets } = data;
    const promotionService = this;
    for (const ticket of associatedTickets) {
      promotionService.addRemovePromotionTask(ticket);
    }
    this.handleShowingPromotionLimitModals(data, showPromotionLimitModal, {
      onlyShowOneTimes: true,
      codeAppliedBefore: false
    });
  }

  public recalculatePromotion(
    ticketId: string,
    showPromotionLimitModal: (message: { title: string; description: string }) => void
  ) {
    this.cartService.recalculatePromotion(ticketId, [], data => {
      this.handleShowingPromotionLimitModals(data, showPromotionLimitModal, {
        onlyShowOneTimes: true,
        codeAppliedBefore: true
      });
    });
  }

  public handleShowingPromotionLimitModals = (
    data: UpdatedPromotionInfo,
    showModal: (message: { title: string; description: string }) => void,
    options: {
      onlyShowOneTimes: boolean;
      codeAppliedBefore: boolean;
    }
  ) => {
    const { associatedTickets, ticketPromotions, promoLockId, requestOverMaxLimit, originalLimit } = data;
    const { onlyShowOneTimes } = options;

    if (onlyShowOneTimes) {
      if (this.showedPromotionModals.has(promoLockId)) return;
    }

    const totalAssociatedTicketQty = associatedTickets.reduce((total, { packCount, quantity }) => {
      const ticketsPerPack = packCount > 1 ? quantity * packCount : quantity;
      return total + ticketsPerPack;
    }, 0);

    const offeredPromoQty = Array.from(ticketPromotions.values()).reduce((total, { promotion }) => {
      return total + promotion.discountQuantity;
    }, 0);

    const isExceededMaxLimit = requestOverMaxLimit && offeredPromoQty === MAX_TICKETS_PER_PROMO_PER_ORDER;
    if (totalAssociatedTicketQty > offeredPromoQty || isExceededMaxLimit) {
      const offeredPromoQtyString = `${offeredPromoQty} ${pluralize('Ticket', offeredPromoQty)}`;
      const title = isExceededMaxLimit
        ? `Only ${offeredPromoQtyString} per code per order`
        : `Only ${offeredPromoQty < originalLimit ? 'remaining ' : ''}${offeredPromoQtyString} Per Code`;
      const message = {
        title,
        description: `Promo code will be applied to ${offeredPromoQty} ${pluralize('ticket', offeredPromoQty)}.`
      };
      showModal(message);
      this.showedPromotionModals.add(promoLockId);
    }
  };

  static validatePromoLockResponses(responses: PromotionLockResponse[]): string {
    const errorResponses = responses.filter(res => 'message' in res) as PromotionLockResponseError[];
    const errorMessages = errorResponses.map(item => item.message.toLocaleLowerCase());

    if (errorMessages.length === responses.length) {
      if (errorMessages.every(isNotExistedCode)) {
        return PROMOTION_ERROR_MESSAGES.CODE_IS_NOT_EXISTED;
      }
      if (errorMessages.length > 1 && errorMessages.every(isCodeNotAvailable)) {
        return multiCodeIsNotAvailableMessage(errorMessages);
      } else {
        const errorMessage = errorMessages[0].toLowerCase() as string;
        if (isCodeNotAvailable(errorMessage)) {
          return codeIsNotAvailableMessage(errorMessage);
        } else {
          //@ts-ignore
          const errorKey = Object.keys(PROMOTION_ERRORS).find(key => errorMessage.includes(PROMOTION_ERRORS[key]));
          if (errorKey) {
            //@ts-ignore
            return PROMOTION_ERROR_MESSAGES[errorKey] as string;
          }
          return PROMOTION_ERROR_MESSAGES.UNKNOWN_ERROR;
        }
      }
    } else {
      if (responses.every(isInvalidPromoCode)) {
        return PROMOTION_ERROR_MESSAGES.CODE_IS_NOT_VALID;
      }
    }
    return '';
  }

  static getRelationWithCarts(promotions: Promotion[]) {
    const cartService = Cart.getInstance();

    const briefPromotions = promotions.map(({ promoCode, _embedded }) => ({
      promoCode,
      associatedProductIds: _embedded.promotion.productAssociations.map(({ productId }) => productId)
    }));

    const isAnyPromotionsExistedInCart = briefPromotions.some(({ associatedProductIds }) =>
      cartService.isAnyProductIdsExistedInCart(associatedProductIds)
    );

    const isAllPromotionsHaveBeenApplied = briefPromotions.every(({ promoCode, associatedProductIds }) =>
      cartService.isPromoCodeAppliedOnAllAssociatedProducts(associatedProductIds, promoCode)
    );

    const isAllAssociatedTicketsHavePromotion = briefPromotions.every(({ associatedProductIds }) =>
      cartService.isEveryAssociatedProductsHasPromo(associatedProductIds)
    );

    return {
      isAnyPromotionsExistedInCart,
      isAllPromotionsHaveBeenApplied,
      isAllAssociatedTicketsHavePromotion
    };
  }

  static applyPromoToCart(
    promotions: Promotion[],
    onCodeAdded: (data: UpdatedPromotionInfo) => void,
    onError: (responses: PromotionLockResponse, errorMessage: string | null) => void
  ) {
    const cartService = Cart.getInstance();
    for (const promotion of promotions) {
      cartService.applyPromotion(promotion, onCodeAdded, onError);
    }
  }

  static removePromotionLockByPromoId = (promoId: number, onPromoRemoved?: (promoId: number) => void) => {
    const cartService = Cart.getInstance();
    cartService.removePromotionLockByPromoId(promoId, onPromoRemoved);
  };

  static getDiscountInfo = () => {
    const cartService = Cart.getInstance();
    return cartService.getDiscountInfo();
  };
}

export const getPromotionSubInfo = ({ discountQuantity, promotionData }: TicketCartPromotion) => {
  const discountTextValue =
    promotionData?.discountType === DISCOUNT_TYPES.PERCENT
      ? `${promotionData?.value}%`
      : `$${Number(promotionData?.value).toFixed(2)}`;
  const subText =
    discountQuantity === 0
      ? `Not applied, limit exceeded`
      : `Applied to ${discountQuantity} ${pluralize('ticket', discountQuantity)}`;
  return `(-${discountTextValue}) - ${subText}`;
};

export const getDiscountSubTitle = (item: DiscountPromoInfo) => {
  return `${item.code} (${item.discountType === 'percent' ? `${item.value}%` : `$${item.value}`} Off)`;
};
