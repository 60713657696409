import { css } from '@gf/cross-platform-lib/styled-components';
import { fontFamilies, fontWeights } from '@gf/cross-platform-lib/styled-system/typography/fonts';

export const styles = {
  descriptionWrapper: css<{ isMobile: boolean }>`
    border-radius: ${({ isMobile }) => (isMobile ? '0px' : '0px')};
    background: rgb(244, 244, 244);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    background: rgb(255, 248, 225);
    height: 100%;
  `,
  seatsHeldWrapper: css`
    display: flex;
    flex-direction: row;
    background: rgb(255, 248, 225);
    padding: 4px;
  `,
  helperWarningText: css`
    font-family: ${fontFamilies.RubikRegular};
    font-size: 12px;
    line-height: 18px;
    font-weight: ${fontWeights.regular};
    letter-spacing: 0.14px;
    color: rgb(22, 22, 22);
    margin-left: 4px;
    margin-top: -1.5px;
  `
};
