import { Cart, EventCart, SchoolCart, SeasonCart, TicketCart } from '@gf/cross-platform-lib/models';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { useCartState } from './useCartState';
import { EVENT_LIMIT, ORDER_LIMIT, ORDER_LIMIT_PER_TICKET, TICKET_LIMIT } from '@gf/cross-platform-lib/constants';

const SALE_SOLD_OUT_ERROR_MESSAGE =
  "We're sorry, the number of tickets selected are no longer available. " +
  'Please adjust the number of tickets to continue.';

const SALE_OVERLIMIT_ERROR_MESSAGE =
  "We're sorry, the number of tickets selected are no longer available. " +
  'Please adjust the number of tickets to continue.';

const getEventSaleRemaining = (event: EventCart | SeasonCart, callback: any) => {
  const { eventTotalRemainingQuantity, eventSoldOut, ticketLimitPerOrder = 0 } = event.eventSalesInfo || {};
  if (eventSoldOut || eventTotalRemainingQuantity <= 0) {
    return 0;
  }
  let remainingSale =
    ticketLimitPerOrder > 0 && ticketLimitPerOrder < eventTotalRemainingQuantity
      ? ticketLimitPerOrder
      : eventTotalRemainingQuantity;
  const cloneEventTickets = cloneDeep(event.tickets).sort((a, b) => (a.updateAt ?? 0) - (b.updateAt ?? 0));

  cloneEventTickets.forEach(ticket => {
    const { quantity = 0, packCount = 0 } = ticket;
    const ticketQuantity = packCount > 1 ? quantity * packCount : quantity;
    if (remainingSale < ticketQuantity && callback !== undefined) {
      callback(ticket.id, remainingSale < 0 ? 0 : remainingSale);
    }

    remainingSale = remainingSale - ticketQuantity;
  });

  return remainingSale;
};

const isEventOverLimit = (event: EventCart | SeasonCart, callback: any) => {
  const remainingSale = getEventSaleRemaining(event, callback);

  return callback === undefined ? remainingSale <= 0 : remainingSale < 0;
};

const sortTicketsCart = (cartSchools: SchoolCart[]): SchoolCart[] => {
  const updateCartSchools = [...cartSchools];
  updateCartSchools.forEach(school =>
    school.events.forEach(event =>
      event.tickets.sort((ticketA, ticketB) => {
        if (ticketA.price === ticketB.price) {
          if (ticketA.name < ticketB.name) {
            return -1;
          }
          if (ticketA.name > ticketB.name) {
            return 1;
          }
          return 0;
        }
        return ticketB.price - ticketA.price;
      })
    )
  );
  return updateCartSchools;
};

const isTicketOverLimit = (ticket: TicketCart, event: EventCart | SeasonCart) => {
  const { packCount, quantity } = ticket;
  const { eventTotalRemainingQuantity, productSalesMap } = event.eventSalesInfo || {};
  const ticketSalesInfo = productSalesMap[ticket.id];
  const { soldOut, remainingQuantity } = ticketSalesInfo;

  if (eventTotalRemainingQuantity && packCount > 1 && eventTotalRemainingQuantity < packCount) {
    return true;
  }

  if (soldOut || remainingQuantity <= 0) {
    return true;
  }

  const ticketQuantity = packCount > 1 ? quantity * packCount : quantity;
  return ticketQuantity > remainingQuantity;
};

const getSchoolSalesMap = (event: EventCart | SeasonCart) => {
  if (isEmpty(event.schoolsTicket) || isEmpty(event)) {
    return {};
  }
  const { schoolSalesMap } = event.eventSalesInfo || {};

  const salesMap = isEmpty(schoolSalesMap) ? {} : { ...schoolSalesMap };

  return event.schoolsTicket.reduce((res: any, school: { schoolId: string | number; schoolLimit: number }) => {
    const salesTotal = !salesMap[school.schoolId] || salesMap[school.schoolId] <= 0 ? 0 : salesMap[school.schoolId];

    const salesRemaining: number = !school.schoolLimit ? school.schoolLimit : school.schoolLimit - salesTotal;

    const soldOut = school.schoolLimit !== null && school.schoolLimit !== undefined && salesRemaining <= 0;

    return {
      ...res,
      [school.schoolId]: {
        soldOut,
        salesTotal,
        salesRemaining,
        maxCapacity: school.schoolLimit,
        schoolDistributionId: school.schoolId
      }
    };
  }, {});
};

const groupTicketsBySchool = (event: EventCart | SeasonCart) =>
  event.tickets.reduce((res: any, tkType) => {
    if (isEmpty(res[tkType?.schoolHuddleId || ''])) {
      res[tkType.schoolHuddleId || ''] = [tkType];
    } else {
      res[tkType.schoolHuddleId || ''].push(tkType);
    }
    return res;
  }, {});

const isTicketOverLimitOfSchool = (
  ticketCards: TicketCart[],
  schoolSalesMap: any,
  updater: (arg0: any, arg1: number, arg2: any) => void
) => {
  const { maxCapacity, salesRemaining } = schoolSalesMap;

  if (maxCapacity === null || maxCapacity === undefined) {
    return false;
  }

  let remaining = salesRemaining;

  [...ticketCards]
    .sort((a, b) => (a.updateAt || 0) - (b.updateAt || 0))
    .forEach((ticket: TicketCart) => {
      const { quantity, packCount } = ticket;

      const ticketQuantity = packCount > 1 ? quantity * packCount : quantity;

      if (remaining < ticketQuantity) {
        updater(ticket.id, remaining < 0 ? 0 : remaining, salesRemaining);
      }

      remaining = remaining - ticketQuantity;
    });

  return remaining <= 0;
};

const isSchoolOverLimit = (
  tickets: TicketCart[],
  schoolSalesMap: any,
  updater: (arg0: any, arg1: number, arg2: any) => void
) => {
  if (isEmpty(schoolSalesMap) || schoolSalesMap.maxCapacity === null || schoolSalesMap.maxCapacity === undefined) {
    return '';
  }
  let isOverLimit = false;
  const cloneTickets = cloneDeep(tickets);

  if (schoolSalesMap.salesRemaining === 0) {
    cloneTickets.forEach(ticket => {
      const { quantity } = ticket;
      if (!isEmpty(ticket)) {
        if (quantity > 0) {
          isOverLimit = true;
          updater(ticket.id, 0, schoolSalesMap.salesRemaining);
        } else if (quantity <= 0) {
          isOverLimit = true;
        }
      }
    });
    return isOverLimit ? SALE_SOLD_OUT_ERROR_MESSAGE : '';
  }
  isOverLimit = isTicketOverLimitOfSchool(tickets, schoolSalesMap, updater);
  return isOverLimit ? SALE_OVERLIMIT_ERROR_MESSAGE : '';
};

const schoolLimitValidation = ({ event }: { event: EventCart | SeasonCart }) => {
  let ticketsNeedToAdjust: any = {};
  let errorMessage = '';
  if (isEmpty(event.schoolsTicket)) {
    return {
      errorMessage,
      ticketsNeedToAdjust
    };
  }

  event?.tickets.forEach(tk =>
    event.ticketTypes.forEach(tkType => {
      if (`${tk.id}` === `${tkType.id}`) {
        tk.schoolHuddleId = tkType.schoolHuddleId;
      }
    })
  );

  const ticketsGroupBySchoolId = groupTicketsBySchool(event);

  Object.keys(ticketsGroupBySchoolId).forEach(schoolId => {
    const overLimitError = isSchoolOverLimit(
      ticketsGroupBySchoolId[schoolId],
      getSchoolSalesMap(event)[schoolId],
      (ticketId: string, remainingSale: number) => {
        ticketsNeedToAdjust[ticketId] = remainingSale;
      }
    );
    if (overLimitError) {
      errorMessage = SALE_OVERLIMIT_ERROR_MESSAGE;
    }
    return {
      ticketsNeedToAdjust
    };
  });

  return {
    errorMessage,
    ticketsNeedToAdjust,
    salesAvailable: ticketsGroupBySchoolId
  };
};

const eventLimitValidation = (event: EventCart | SeasonCart) => {
  const ticketsNeedToAdjust: { [p: string]: number } = {};
  let errorMessage: string = '';
  const { eventTotalRemainingQuantity, eventSoldOut, ticketLimitPerOrder } = event?.eventSalesInfo || {};
  if (eventSoldOut || eventTotalRemainingQuantity <= 0) {
    errorMessage = SALE_SOLD_OUT_ERROR_MESSAGE;
  } else {
    const isOverLimit = isEventOverLimit(event, (ticketId: number, remainingSale: number) => {
      ticketsNeedToAdjust[ticketId] = remainingSale;
    });

    if (isOverLimit) {
      errorMessage = SALE_OVERLIMIT_ERROR_MESSAGE;
    }
  }
  return {
    ticketsNeedToAdjust,
    errorMessage,
    eventTotalRemainingQuantity:
      ticketLimitPerOrder > 0 && ticketLimitPerOrder < eventTotalRemainingQuantity
        ? ticketLimitPerOrder
        : eventTotalRemainingQuantity,
    limitType: ticketLimitPerOrder > 0 && ticketLimitPerOrder < eventTotalRemainingQuantity ? ORDER_LIMIT : EVENT_LIMIT
  };
};

const ticketLimitValidation = ({ event, ticket }: { event: EventCart | SeasonCart; ticket: TicketCart }) => {
  let errorMessage = '';
  const ticketsNeedToAdjust: { [p: string]: number } = {};
  let remainingQuantity = Number.MAX_VALUE;
  const { productSalesMap } = event.eventSalesInfo || {};
  if (productSalesMap) {
    const ticketSalesInfo = productSalesMap[ticket.id];
    const { soldOut } = ticketSalesInfo;
    remainingQuantity = ticketSalesInfo.remainingQuantity;
    if (soldOut || remainingQuantity <= 0) {
      errorMessage = SALE_SOLD_OUT_ERROR_MESSAGE;
      ticketsNeedToAdjust[ticket.id] = 0;
    } else {
      if (isTicketOverLimit(ticket, event)) {
        errorMessage = SALE_OVERLIMIT_ERROR_MESSAGE;

        ticketsNeedToAdjust[ticket.id] = remainingQuantity;
      }
    }
  }
  return {
    ticketsNeedToAdjust,
    errorMessage,
    remainingQuantity
  };
};

const adjustTicketQuantity = (
  cartSchools: SchoolCart[],
  ticketId: number | string,
  limitInstant: { ticketsNeedToAdjust: { [p: string]: number } },
  cartEvent: EventCart | SeasonCart
): number => {
  let hasChange = false;
  let changedQuantity = 0;
  let numberTicketLeft = 0;

  cartEvent.tickets.forEach(ticket => {
    if (isEqual(ticket.id, ticketId)) {
      hasChange = true;
      changedQuantity =
        ticket.packCount > 1
          ? Number.parseInt(String(limitInstant.ticketsNeedToAdjust[ticket.id] / ticket.packCount), 10)
          : limitInstant.ticketsNeedToAdjust[ticket.id];
      ticket.quantity = changedQuantity;
    }
    if (hasChange) {
      numberTicketLeft = changedQuantity;
    }
  });
  return numberTicketLeft;
};

export const useCartLimit = () => {
  const { cartSchools } = useCartState();
  const cartService = Cart.getInstance();

  let eventLimitInstant: {
    limitType: string;
    ticketsNeedToAdjust: { [p: string]: number };
    eventTotalRemainingQuantity: number;
  } = {
    limitType: EVENT_LIMIT,
    ticketsNeedToAdjust: {},
    eventTotalRemainingQuantity: 0
  };
  let ticketLimitInstant: {
    remainingQuantity: number;
    ticketsNeedToAdjust: { [p: string]: number };
  } = {
    ticketsNeedToAdjust: {},
    remainingQuantity: 0
  };
  let schoolLimitInstant: { ticketsNeedToAdjust: { [p: string]: number }; salesAvailable?: any } = {
    ticketsNeedToAdjust: {},
    salesAvailable: {}
  };

  let numberTicketLeft = 0;
  let ticketLeftPopup = 0;
  let limitType = TICKET_LIMIT;

  const limitValidation = ({
    ticketId,
    eventId,
    quantity,
    eventDetailCartEvent
  }: {
    ticketId: string;
    eventId: string;
    quantity: number;
    eventDetailCartEvent?: EventCart;
  }) => {
    let removeThisTicket = false;
    const cartEvent: EventCart | SeasonCart = eventDetailCartEvent
      ? eventDetailCartEvent
      : cartService.getEventWithDistributionSchools(eventId);

    const foundOriginalTicket = cartEvent?.tickets.find(ticket => `${ticket.id}` === `${ticketId}`);

    if (!isEmpty(cartEvent) && !isEmpty(foundOriginalTicket)) {
      cartEvent.tickets.forEach(ticket => {
        if (isEqual(ticket.id, ticketId)) {
          ticket.quantity = quantity;
          ticket.updateAt = Date.now();
        }
      });
      eventLimitInstant = eventLimitValidation(cartEvent);

      if (!isEmpty(eventLimitInstant.ticketsNeedToAdjust)) {
        numberTicketLeft = adjustTicketQuantity(cartSchools, ticketId, eventLimitInstant, cartEvent);
        ticketLeftPopup = eventLimitInstant.eventTotalRemainingQuantity;
        limitType = eventLimitInstant.limitType;
        if (!numberTicketLeft) removeThisTicket = true;
      }

      schoolLimitInstant = schoolLimitValidation({
        event: cartEvent
      });

      if (!isEmpty(schoolLimitInstant.ticketsNeedToAdjust)) {
        const foundSchoolHuddleId =
          cartEvent.ticketTypes.find(tkType => `${tkType.id}` === `${ticketId}`)?.schoolHuddleId || '';
        const getEventSalesMap = getSchoolSalesMap(cartEvent);
        numberTicketLeft = adjustTicketQuantity(cartSchools, ticketId, schoolLimitInstant, cartEvent);
        ticketLeftPopup = ticketLeftPopup ? ticketLeftPopup : getEventSalesMap[foundSchoolHuddleId]?.salesRemaining;
        limitType = EVENT_LIMIT;
        if (!numberTicketLeft) removeThisTicket = true;
      }

      ticketLimitInstant = ticketLimitValidation({
        event: cartEvent,
        ticket: foundOriginalTicket
      });

      if (!isEmpty(ticketLimitInstant.ticketsNeedToAdjust)) {
        const remainingQty = cartEvent.ticketTypes.find(tk => tk.id === ticketId)?.productSales?.remainingQuantity || 0;
        numberTicketLeft = adjustTicketQuantity(cartSchools, ticketId, ticketLimitInstant, cartEvent);
        ticketLeftPopup = ticketLeftPopup ? ticketLeftPopup : ticketLimitInstant.remainingQuantity;
        limitType =
          foundOriginalTicket?.ticketLimitPerOrder && foundOriginalTicket?.ticketLimitPerOrder < remainingQty
            ? ORDER_LIMIT_PER_TICKET
            : TICKET_LIMIT;
        if (!numberTicketLeft) removeThisTicket = true;
      }
    }
    return {
      numberTicketLeft,
      ticketLeftPopup,
      limitType,
      removeThisTicket
    };
  };

  return { limitValidation, sortTicketsCart, eventLimitValidation, schoolLimitValidation, ticketLimitValidation };
};
