import {
  debouncedPromise,
  GetCurrentApplicationConfiguration,
  Method,
  recordError
} from '@gf/cross-platform-lib/utils';
import { safeFetch } from '../utils';
import { SafeFetchResponse, TaxItem, TaxRequest, TaxResponse } from '@gf/cross-platform-lib/interfaces';
import { NEW_RELIC_ERROR_GROUPS } from '@gf/cross-platform-lib/constants';

const appConfig = GetCurrentApplicationConfiguration();
const timeoutPromise = (ms: number) =>
  new Promise<SafeFetchResponse<TaxResponse>>((resolve, _) =>
    setTimeout(() => resolve({ data: null, error: { message: 'timeout', code: 408, data: null }, status: 408 }), ms)
  );

export const calcTax = (items: TaxItem[]) =>
  Math.round(items.reduce((total, item) => total + (item.tax_fee ?? 0) + (item.tax_price ?? 0), 0) * 100) / 100;

export const getEstimateTax = async (request: TaxRequest) => {
  if (request?.items?.length === 0) {
    return 0;
  }
  const url = `${appConfig.api.taxService.baseUrl}/estimate-tax`;
  let tax = 0;
  try {
    const res = await Promise.race([
      safeFetch<TaxResponse>(
        url,
        {
          method: Method.POST,
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(request)
        },
        { redirect404: false }
      ),
      timeoutPromise(10000)
    ]);

    if (res?.data === null) {
      recordError(res.error?.data || '', {
        customMessage: `get estimate tax failed.`,
        originatingFunction: 'useGetTax',
        errorGroup: NEW_RELIC_ERROR_GROUPS.APIRequests,
        data: { request }
      });
    } else {
      const items = res?.data?.items ?? [];
      tax = calcTax(items);
    }
  } catch (error) {
    recordError(error instanceof Error ? error : new Error(String(error)), {
      customMessage: `get estimate tax failed.`,
      originatingFunction: 'useGetTax',
      errorGroup: NEW_RELIC_ERROR_GROUPS.APIRequests,
      data: { request }
    });
  }
  return tax;
};

export const debouncedGetEstimateTax = (request: TaxRequest) => debouncedPromise(getEstimateTax, 500)(request);
