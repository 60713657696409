import { Cart, TicketCart } from '@gf/cross-platform-lib/models';
import isEmpty from 'lodash/isEmpty';
import { deletePromoLock, updatePromoLock } from '@gf/cross-platform-lib/modules/AcquisitionV2';

export const getAccessCodeLimit = async (ticketId: string, requestedQuantity: number) => {
  const cartService = Cart.getInstance();

  const foundTicket = cartService.getTicket(ticketId);

  const accessCodeResponse = await updateAccessCodeTicket(foundTicket, requestedQuantity);

  const { quantityLocked = 0 } = accessCodeResponse && 'quantityLocked' in accessCodeResponse ? accessCodeResponse : {};

  return {
    exceedLimit: quantityLocked < requestedQuantity,
    accessCodeLimit: quantityLocked
  };
};

export const checkPackCountAdjusted = (
  res: { quantityLocked: number; quantityRequested: number },
  packCount = 0,
  updateAccessCode: (quantity: number) => {}
) => {
  if (
    !isEmpty(res) &&
    packCount > 1 &&
    getPackCountQuantity(res.quantityLocked, packCount) * packCount < res.quantityRequested
  ) {
    return updateAccessCode(getPackCountQuantity(res.quantityLocked, packCount) * packCount);
  }
  return;
};

export const updateAccessCodeTicket = async (ticket: TicketCart, quantity: number) => {
  const cartService = Cart.getInstance();
  if (!ticket.promos || ticket.promos.length === 0) {
    return;
  }
  let promoResponse = await updatePromoLock(ticket.promos[0], quantity);
  if (
    ('message' in promoResponse && promoResponse.message.includes('Promo Code Lock is not found for request')) ||
    ('message' in promoResponse && promoResponse.message.includes('Promo Code is not Found for request'))
  ) {
    await deletePromoLock(ticket.promos[0]);
    await cartService.removeTicket(ticket.id);
  }
  if ('id' in promoResponse) {
    checkPackCountAdjusted(promoResponse, ticket.packCount, updatedQuantity =>
      updatePromoLock(ticket.promos ? ticket.promos[0] : '', updatedQuantity)
    );
  }
  return promoResponse;
};

export const getPackCountQuantity = (qty: number, packCount: number = 1) => {
  const adjusted = Math.round(qty / packCount);
  return packCount > 1 ? (adjusted * packCount > qty ? adjusted - 1 : adjusted) : qty;
};
