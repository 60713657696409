import { doesExist } from './doesExist';

export type ClosedRange = {
  min?: number;
  max?: number;
};

export const withinRange = (value: number, range: ClosedRange, increment?: number) => {
  let isWithinRange = true;
  let canDecrement = true;
  let canIncrement = true;
  if (doesExist(range.min)) {
    isWithinRange = isWithinRange && value >= range.min!;
    if (doesExist(increment)) {
      canDecrement = value - increment! >= range.min!;
    }
  }

  if (doesExist(range.max)) {
    isWithinRange = isWithinRange && value <= range.max!;
    if (doesExist(increment)) {
      canIncrement = value + increment! <= range.max!;
    }
  }

  return {
    isWithinRange,
    canDecrement,
    canIncrement
  };
};
