import React, { createContext, useContext, useEffect, useState } from 'react';
import { Cart, createTask, Task } from '../../models';
import { MODAL_TYPES, useModal } from '../ModalProvider';
import { useCartState, useNavigate } from '../../hooks';
import { DEFAULT_HOLDING_ACCESS_CODE_TIME, GOFAN_APP_PAGES } from '../../constants';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import min from 'lodash/min';

interface AccessCodeContextType {
  addRemoveAccessCodeTask: (
    removeAccessCodeInEventDetail: (code?: string, showModal?: Function) => void,
    eventId: string,
    code: string,
    updatedTicketAt?: number
  ) => void;
  clearTask: (code: string) => void;
  clearAllTasks: () => void;
  getAccessCodeRemainingTime: () => number;
}

interface AccessCodeProviderProps {
  children: React.ReactNode;
  currentRoute: string;
}

const AccessCodeContext = createContext<AccessCodeContextType>({
  addRemoveAccessCodeTask: () => {},
  clearTask: () => {},
  clearAllTasks: () => {},
  getAccessCodeRemainingTime: () => 0
});

export const AccessCodeProvider = ({ children }: AccessCodeProviderProps) => {
  const [tasks, setTasks] = useState<Map<string, Task>>(new Map<string, Task>());
  const [listRemainingAt, setListRemainingAt] = useState<number[]>([]);
  const cartService = Cart.getInstance();
  const navigate = useNavigate();
  const { showModal, hideModal } = useModal();
  const { cartSchools } = useCartState();

  const clearTask = (taskId = '') => {
    clearTimeout(tasks.get(taskId)?.timer);
    tasks.delete(taskId);
  };

  const clearAllTasks = () => {
    tasks.forEach(task => clearTask(task.taskId));
  };

  const addRemoveAccessCodeTask = (
    removeAccessCodeInEventDetail: (code?: string, showModal?: Function) => void,
    eventId = '',
    code = '',
    createAt = 0
  ) => {
    const remainingTime = createAt + DEFAULT_HOLDING_ACCESS_CODE_TIME - Date.now();
    if (remainingTime > 0) {
      setListRemainingAt([...listRemainingAt, createAt]);
    }
    if (tasks.get(code)) {
      return;
    }

    const handleShowModal = () => {
      showModal(MODAL_TYPES.ACCESS_CODE_MODAL, {
        title: `Your access code ${code} has expired!`,
        description: `We’re sorry, but access codes can only be held for 10 minutes. Your code has been removed and you must reapply the access code.`,
        onConfirm: () => {
          clearTask(task?.taskId);
          navigate(GOFAN_APP_PAGES.event.getPath(eventId));
          hideModal();
        },
        hideModalWhenClickOutSite: false,
        largeContent: true
      });
    };

    const task = createTask(remainingTime, code, () => {
      removeAccessCodeInEventDetail(code, handleShowModal);
      cartService.validateAccessLockInCart(code, handleShowModal);
    });

    if (task) {
      const { taskId } = task;
      tasks.set(taskId, task);
      setTasks(tasks);
    }
  };

  //get remaining time to reset access code
  const getAccessCodeRemainingTime = () => {
    const newListRemainingAt = listRemainingAt.filter(time => time + DEFAULT_HOLDING_ACCESS_CODE_TIME - Date.now() > 0);
    setListRemainingAt(newListRemainingAt);

    const remainingAt = min(newListRemainingAt) || 0;
    const expireTime = remainingAt + DEFAULT_HOLDING_ACCESS_CODE_TIME - Date.now();

    if (expireTime < 0) {
      return 0;
    } else if (expireTime > DEFAULT_HOLDING_ACCESS_CODE_TIME) {
      return DEFAULT_HOLDING_ACCESS_CODE_TIME;
    }
    return expireTime;
  };

  //Handle trigger new tasks when reload browser
  useEffect(() => {
    if (isEmpty(tasks)) {
      const ticketsPromo = cartService.getTicketCartsWithAccessCode();
      if (isEmpty(ticketsPromo)) {
        return;
      }
      const groupByTickets = groupBy(ticketsPromo, 'accessCode');
      Object.entries(groupByTickets).forEach(([code, ticketCart]) => {
        const foundEvent = cartSchools
          .map(school => school.events)
          .flat()
          .find(event => event.tickets.some(ticket => ticket.id === ticketCart[0].id));
        addRemoveAccessCodeTask(() => {}, foundEvent?.id || '', code, ticketCart[0]?.createAt);
      });
    }
  }, []);

  return (
    <AccessCodeContext.Provider
      value={{ addRemoveAccessCodeTask, clearTask, clearAllTasks, getAccessCodeRemainingTime }}
    >
      {children}
    </AccessCodeContext.Provider>
  );
};
export const useAccessCodeProvider = () => useContext(AccessCodeContext);
