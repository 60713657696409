// @ts-nocheck
import * as React from 'react';
import Svg, { Path, SvgProps } from 'react-native-svg';
import { Platform } from 'react-native';

export const AddIcon = (props: SvgProps) => (
  <Svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox={Platform.OS === 'android' || Platform.OS === 'ios' ? '0 0 32 32' : '0 -5 32 32'}
    style={{
      enableBackground: 'new 0 0 32 32'
    }}
    fill='currentColor'
    xmlSpace='preserve'
    {...props}
  >
    <Path d='M17 15V8h-2v7H8v2h7v7h2v-7h7v-2z' />
    <Path
      style={{
        fill: 'none'
      }}
      d='M0 0h32v32H0z'
    />
  </Svg>
);
