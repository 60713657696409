import { useEffect, useState } from 'react';
const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export const useTimer = (expiresAt: Date | null, onExpire: () => void) => {
  const initialTimespan = expiresAt ? expiresAt.getTime() - Date.now() : 0;
  const [timespan, setTimespan] = useState(initialTimespan);

  useEffect(() => {
    if (!expiresAt) {
      return;
    }

    const intervalId = setInterval(() => {
      const newTimespan = expiresAt.getTime() - Date.now();
      setTimespan(newTimespan);

      if (newTimespan < 0) {
        clearInterval(intervalId);
        if (onExpire) {
          onExpire();
        }
      }
    }, SECOND);

    return () => {
      clearInterval(intervalId);
    };
  }, [expiresAt, onExpire]);

  return {
    days: Math.floor(timespan / DAY),
    hours: Math.floor((timespan / HOUR) % 24),
    minutes: Math.floor((timespan / MINUTE) % 60),
    seconds: Math.floor((timespan / SECOND) % 60)
  };
};
