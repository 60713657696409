import { css } from '@gf/cross-platform-lib/styled-components';

export const styles = {
  descriptionWrapper: css`
    border: solid rgba(253, 209, 58, 0.3);
    border-radius: 4px;
    background: rgb(255, 248, 225);
    border-left-width: 4px;
    border-left-color: #f1c21c;
    padding: 14px;
    margin-top: 16px;
  `,
  removedTextWrapper: css`
    flex-direction: row;
  `,
  removedText: css<{ isMobile: boolean }>`
    flex-direction: column;
    width: ${({ isMobile }) => (isMobile ? '85%' : '100%')};
  `
};
