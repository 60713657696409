import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AddIcon, SubtractIcon } from '@gf/cross-platform-lib/components';

import { styles } from './NumberInput.styles';
import { reviewAndBuyPage, Tall, testProperties, withinRange } from '@gf/cross-platform-lib/utils';
import { DEFAULT_BlACK_COLOR, INVALID_COLOR } from '@gf/cross-platform-lib/constants';
import { INPUT_SIZES, InputChangeType } from './contants';

/*
 *  This web's version is supposed to handle hover and active CSS
 * */

const Input = styled.input.attrs({ tabIndex: 0 })`
  ${styles.inputNumber};
`;
const InputWrapper = styled.div`
  ${styles.inputWrapper}
`;
const NumberControls = styled.div`
  ${styles.numberControls};
`;
const IconWrapper = styled.button.attrs({ tabIndex: 0, 'aria-label': 'decrement' })`
  ${styles.button}
`;
const IconWrapperRight = styled.button.attrs({ tabIndex: 0, 'aria-label': 'increment' })`
  ${styles.button}
  right: 0;
`;

export type NumberInputProps = {
  label?: string;
  value: number;
  size: Tall;
  width?: number;
  disabled?: boolean;
  disabledIncreaseButton?: boolean;
  minimumValue?: number;
  maximumValue?: number;
  maximumLimitMessage?: string;
  increment?: number;
  testID?: string;
  accessibilityLabel?: string;
  onBlur?: (quantity: number, oldQuantity: number) => void;
  onChangeQuantity?: (newQuantity: number, oldValue: number, changeType?: InputChangeType) => void;
  onDecrease?: (newQuantity: number, oldValue: number) => void;
  onIncrease?: (newQuantity: number, oldValue: number) => void;
};

let oldQuantity = 0;

export const NumberInput = ({
  label,
  disabled = false,
  minimumValue = 0,
  maximumValue = 999,
  increment = 1,
  value,
  size = 'lg',
  width,
  testID,
  onChangeQuantity,
  onBlur,
  onDecrease,
  onIncrease,
  disabledIncreaseButton
}: NumberInputProps) => {
  const [quantity, setQuantity] = useState(value);
  const { canDecrement, canIncrement } = withinRange(quantity, { min: minimumValue, max: maximumValue }, increment);

  const incrementCounter = (_e: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = quantity + increment;
    if (canIncrement) {
      oldQuantity = quantity;
      setQuantity(newQuantity);
      if (typeof onIncrease !== 'undefined') {
        onIncrease(newQuantity, quantity);
      } else if (typeof onChangeQuantity !== 'undefined') {
        onChangeQuantity(
          newQuantity <= maximumValue ? newQuantity : maximumValue,
          quantity,
          InputChangeType.ON_INCREMENT
        );
      }
    }
  };

  const decrementCounter = (_e: React.MouseEvent<HTMLElement> | React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = quantity - increment;
    if (canDecrement) {
      oldQuantity = quantity;
      setQuantity(newQuantity);
      if (typeof onDecrease !== 'undefined') {
        onDecrease(newQuantity, quantity);
      } else if (typeof onChangeQuantity !== 'undefined') {
        onChangeQuantity(
          newQuantity <= maximumValue ? newQuantity : maximumValue,
          quantity,
          InputChangeType.ON_DECREMENT
        );
      }
    }
  };

  const updateQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuantity = parseInt(e.currentTarget.value) || 0;

    if (newQuantity >= 0) {
      oldQuantity = quantity;
      setQuantity(newQuantity <= maximumValue ? newQuantity : maximumValue);
      if (typeof onChangeQuantity !== 'undefined') {
        onChangeQuantity(
          newQuantity <= maximumValue ? newQuantity : maximumValue,
          quantity,
          InputChangeType.ON_INPUT_CHANGE
        );
      }
    }
  };

  const onBlurInput = () => {
    if (typeof onBlur !== 'undefined') {
      onBlur(quantity, oldQuantity);
    }
  };

  useEffect(() => {
    setQuantity(value);
  }, [value]);

  const height = INPUT_SIZES[size][1];
  const _width = width || INPUT_SIZES[size][0];
  return (
    <>
      {label ? <div>{label}</div> : null}
      <InputWrapper height={height} width={_width}>
        <NumberControls height={height}>
          <IconWrapper
            {...testProperties(reviewAndBuyPage.button.subtract)}
            disabled={disabled || !canDecrement}
            onClick={decrementCounter}
            height={height}
            accessibilityLabel='decrement'
          >
            <SubtractIcon
              width={16}
              height={16}
              color={disabled || !canDecrement ? INVALID_COLOR : DEFAULT_BlACK_COLOR}
            />
          </IconWrapper>
          <Input
            type='tel'
            value={`${quantity}`}
            onChange={updateQuantity}
            onBlur={onBlurInput}
            maxLength={3}
            disabled={disabled}
            height={height}
            width={_width}
            data-testid={testID}
            aria-label='enter number'
          />
          <IconWrapperRight
            {...testProperties(reviewAndBuyPage.button.add)}
            disabled={disabled || !canIncrement || disabledIncreaseButton}
            onClick={incrementCounter}
            height={height}
          >
            <AddIcon
              width={16}
              height={16}
              color={disabled || !canIncrement || disabledIncreaseButton ? INVALID_COLOR : DEFAULT_BlACK_COLOR}
            />
          </IconWrapperRight>
        </NumberControls>
      </InputWrapper>
    </>
  );
};
