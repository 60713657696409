import React from 'react';
import { View, Text } from 'react-native';
import { styles } from './SeatSelectionCountdown.styles';
import styled from '@gf/cross-platform-lib/styled-components';
import { Carbonicons } from '@gf/cross-platform-lib/components';
import { useTimer } from '@gf/cross-platform-lib/hooks';

const DescriptionWrapper = styled(View)`
  ${styles.descriptionWrapper}
`;

const SeatsHeldWrapper = styled(View)`
  ${styles.seatsHeldWrapper}
`;

const HelperWarningText = styled(Text)`
  ${styles.helperWarningText}
`;
interface SeatSelectionCountdownProps {
  isMobile?: boolean;
  expiresAt: string | Date;
  onTimerExpire: () => void;
}

export const SeatSelectionCountdown = ({ isMobile, expiresAt, onTimerExpire }: SeatSelectionCountdownProps) => {
  const { minutes, seconds } = useTimer(new Date(expiresAt), onTimerExpire);
  if (expiresAt === '') {
    return null;
  }

  const warningText = `Seats held for ${minutes}:${seconds! <= 9 ? '0' + seconds : seconds} minutes`;
  return (
    <DescriptionWrapper isMobile={isMobile}>
      <SeatsHeldWrapper>
        <Carbonicons name='warning-alt-filled' size={14} />
        <HelperWarningText>{warningText}</HelperWarningText>
      </SeatsHeldWrapper>
    </DescriptionWrapper>
  );
};

export default SeatSelectionCountdown;
