import { css } from '@gf/cross-platform-lib/styled-components';

export const baseStyles = {
  inputNumber: css<{ width: number; height: number }>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    border-width: 2px;
    border-color: #dbdbdb;
    border-radius: 6px;
    color: #000000;
  `,
  inputWrapper: css<{ width: number; height: number }>`
    width: ${({ width }) => `${width}px`};
    height: ${({ height }) => `${height}px`};
    position: relative;
    align-items: center;
  `,
  numberControls: css<{ height: number }>`
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: ${({ height }) => `${height}px`};
  `,
  button: css<{ height: number }>`
    background-color: transparent;
    width: ${({ height }) => `${height}px`};
    height: ${({ height }) => `${height}px`};
    border: none;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    position: absolute;
  `
};
